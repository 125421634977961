import Index from '@/views/Fanzone.vue'
import Faq from '@/components/Fanzone/Faq.vue'
import Cheersong from '@/components/Fanzone/Cheersong.vue'
import CheersongView from '@/components/Fanzone/CheersongView.vue'
import Supporters from '@/components/Fanzone/Supporters.vue'
import Event from '@/components/Fanzone/Event.vue'
import EventView from '@/components/Fanzone/EventView.vue'
import EventTempView from '@/components/Fanzone/EventTempView.vue'
import EventPhoto from '@/components/Fanzone/EventPhoto.vue'
import EventBoardView from '@/components/Fanzone/EventPhotoView.vue'
import Survey from '@/components/Survey/View.vue'
// import PlayerSchedule from '@/components/Fanzone/PlayerSchedule'
import ScheduleView from '@/components/Fanzone/ScheduleView'
import Point from "@/components/Fanzone/point.vue"
import PointTicket from "@/components/Fanzone/PointTicket.vue"
import PointMD from "@/components/Fanzone/PointMD.vue"
import Coupon from "@/components/Fanzone/Coupon.vue";


const fanzone = [
  {
    path: '/fanzone',
    name: 'fanzone',
    component: Index,
    redirect: {
      name: 'fanzoneFaq',
      params: {
        cate: 'all'
      }
    },
    children: [
      {
        path: 'faq/:cate',
        name: 'fanzoneFaq',
        component: Faq,
        props: true,
        meta: {
          fanzone: 'faq'
        }
      },
      {
        path: 'cheersong',
        name: 'fanzoneCheersong',
        component: Cheersong,
        meta: {
          fanzone: 'cheersong'
        }
      },
      {
        path: 'cheersong/:id',
        name: 'fanzoneCheersongView',
        component: CheersongView,
        meta: {
          noSubTop: true,
          fanzone: 'cheersong'
        },
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = '/fanzone/cheersong'
          }
          next()
        }
      },
      {
        path: 'supporters',
        name: 'fanzoneSupporters',
        component: Supporters,
        meta: {
          fanzone: 'supporters'
        }
      },
      {
        path: 'playerSchedule/:id',
        name: 'scheduleView',
        meta: {
          noSubTop: true,
          fanzone: 'playerSchedule'
        },
        component: ScheduleView,
        props: true,
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = `/playerSchedule/${to.params.media}` // GNB 마이페이지 아이콘 위치에 back 버튼이 들어오는 경우 metaData로 path 지정
          }
          next()
        }
      },
      {
        path: 'event',
        name: 'fanzoneEvent',
        component: Event,
        meta: {
          fanzone: 'event'
        }
      },
      {
        path: 'event/:id',
        name: 'fanzoneEventView',
        component: EventView,
        meta: {
          noSubTop: true,
          fanzone: 'event'
        },
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = '/fanzone/event'
          }
          next()
        }
      },
      {
        path: 'event/temp/:id',
        name: 'fanzoneEventTempView',
        component: EventTempView,
        meta: {
          noSubTop: true,
          fanzone: 'event'
        },
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = '/fanzone/event'
          }
          next()
        }
      },
      {
        path: 'event/survey/:id',
        name: 'surveyView',
        component: Survey,
        meta: {
          noSubTop: true,
          fanzone: 'event'
        },
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = '/fanzone/event'
          }
          next()
        }
      },
      {
        path: 'point',
        name: 'pointPage',
        component: Point,
        props: true,
        redirect: {
          name: 'ticketPoint',
          params: {
            cate: 'ticketPoint'
          }
        },
        children: [
          {
            path: 'ticketPoint',
            name: 'ticketPoint',
            component: PointTicket,
            meta: {
              fanzone: 'point',
              cate: 'ticketPoint'
            },
          },
          {
            path: 'mdPoint',
            name: 'mdPoint',
            component: PointMD,
            meta: {
              fanzone: 'point',
              cate: 'mdPoint',
            },
          },
          {
            path: 'coupon',
            name: 'coupon',
            component: Coupon,
            meta: {
              fanzone: 'point',
              cate: 'coupon',
            },
          }
        ]
      },
      {
        path: 'eventPhoto',
        name: 'fanzoneEventPhoto',
        component: EventPhoto,
        meta: {
          fanzone: 'eventPhoto'
        }
      },
      {
        path: 'eventPhoto/:id',
        name: 'fanzoneEventBoardView',
        component: EventBoardView ,
        meta: {
          noSubTop: true,
          fanzone: 'eventPhoto'
        },
        beforeEnter: (to, from, next) => {
          if (to) {
            to.meta.historyBack = '/fanzone/eventPhoto'
          }
          next()
        }
      }

    ]
  },

]

export default fanzone
