const menu = [
  {
    name: 'club', // 메뉴 name은 router name으로 한다
    title: 'CLUB',
    intro: 'club', // intro가 있다면 메가메뉴/푸터에 서브메뉴는 노출하고 GNB는 클릭시 해당 router name으로 이동한다
    children: [
      {
        name: 'club',
        title: '클럽',
        params: {
          lang: 'ko'
        }
      }
    ]
  },
  {
    name: 'team',
    title: 'TEAM',
    children: [
      {
        name: 'teamPlayer',
        title: '프로선수단',
        params: {
          position: 'all'
        }
      },
      {
        name: 'teamStaff',
        title: '코칭스태프'
      },
      {
        name: 'teamYouth',
        title: '유소년소개'
      }
      ,
      {
        name: 'teamU18',
        title: 'U18',
        params: {
          position: 'all'
        }
      }
      ,
      {
        name: 'teamU15',
        title: 'U15',
        params: {
          position: 'all'
        }
      }
      ,
      {
        name: 'teamU12',
        title: 'U12',
        params: {
          position: 'all'
        }
      }
    ]
  },
  {
    name: 'match',
    title: 'MATCH',
    children: [
      {
        name: 'matchSchedule',
        title: 'A팀 일정/결과'
      },
      {
        name: 'matchK4',
        title: 'N팀(K3) 일정/결과'
      },
      {
        name: 'matchU18',
        title: 'U18 일정/결과'
      }
      ,
      {
        name: 'matchU15',
        title: 'U15 일정/결과'
      }
      ,
      {
        name: 'matchRanking',
        title: '대회 순위'
      }
      ,
      {
        name: 'matchRecord',
        title: 'K리그 개인 순위'
      }
    ]
  },
  {
    name: 'ticket',
    title: 'TICKET',
    children: [
      {
        name: 'ticket',
        title: '티켓 메인'
      },
      {
        name: 'ticketSeason',
        title: '시즌티켓'
      },
      // {
      //   name: 'groupGuide',
      //   title: '단체 안내'
      // },
      {
        name: 'ticketSkybox',
        title: '스카이박스'
      },
      {
        name: 'ticketStadium',
        title: '비상 대피 안내'
      },
      {
        name: 'ticketReservation',
        title: '나의티켓'
      },
      // {
      //   name: 'ticketServiceFaq',
      //   title: 'FAQ',
      //   params: {
      //     cate: 'all'
      //   }
      // },
      // {
      //   name: 'ticketServiceNotice',
      //   title: '티켓 공지사항'
      // }
    ]
  },
  {
    name: 'history',
    title: 'HISTORY',
    intro: 'history',
    children: [
      {
        name: 'history',
        title: '역사관'
      }
    ]
  },
  {
    name: 'record',
    title: 'RECORD',
    intro: 'record',
    children: [
      {
        name: 'record',
        title: '기록실'
      }
    ]
  },
  {
    name: 'media',
    title: 'MEDIA',
    children: [
      {
        name: 'mediaNotice',
        title: '공지사항'
      },
      {
        name: 'mediaNews',
        title: '구단소식'
      },
      {
        name: 'jbMedia',
        title: 'JB 미디어'
      },
      {
        name: 'mediaPhoto',
        title: '경기사진'
      },
      {
        name: 'mediaWallpaper',
        title: '바탕화면'
      },
      {
        name: 'mediaVideo',
        title: '구단영상'
      }
    ]
  },
  {
    name: 'fanzone',
    title: 'FAN ZONE',
    children: [
      {
        name: 'fanzoneFaq',
        title: 'FAQ',
        params: {
          cate: 'all'
        }
      },
      {
        name: 'pointPage',
        title: '포인트/쿠폰 안내',
      },
      {
        name: 'fanzoneCheersong',
        title: '응원가'
      },
      {
        name: 'fanzoneSupporters',
        title: '서포터즈'
      },
      {
        name: 'fanzoneEvent',
        title: '이벤트'
      },
      {
        name: 'fanzoneEventPhoto',
        title: '이벤트 포토'
      },
      {
        name: 'mypageActivityQna',
        params: {
          page: 1
        },
        title: '1:1 문의하기'
      }
    ]
  },
  {
    name: 'mdshop',
    title: 'MD SHOP',
    menuBar: false, // 메뉴바 노출 X
    footer: false, // 푸터 노출 X
    children: [
      {
        name: 'mdshopIntro',
        title: '초록이네',
        link: 'https://shop.hyundai-motorsfc.com/' // 외부링크로 연결할 때
      }
    ]
  },
  {
    name: 'house',
    title: 'SPONSOR HOUSE',
    menuBar: false, // 메뉴바 노출 X
    footer: false, // 푸터 노출 X
    children: [
      {
        name: 'houseIntro',
        title: '후원의집',
        link: 'https://house.hyundai-motorsfc.com/' // 외부링크로 연결할 때
      }
    ]
  },
  {
    name: 'greenschool',
    title: 'GREEN SCHOOL',
    menuBar: false, // 메뉴바 노출 X
    footer: false, // 푸터 노출 X
    children: [
      {
        name: 'greenschoolIntro',
        title: '그린스쿨',
        link: 'https://greenschool.hyundai-motorsfc.com/' // 외부링크로 연결할 때
      }
    ]
  },
]

export default menu
