<template>
  <div class="subTop hyundai">
    <!-- 서브 상단 -->
    <div class="top">
      <div class="container">
        <div class="breadCrumb">
          홈 <span class="arr">&#8250;</span>
          <router-link :to="depth1.link">{{depth1.name}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth2.name}}</router-link> <span v-if="depth3" class="arr">&#8250;</span>
          <router-link v-if="depth3" :to="depth3.link">{{depth3.name}}</router-link>
        </div>
        <div class="topTitle">
          <p class="cate">{{depth1.name}}</p>
          <p class="subCate">{{depth2.name}}</p>
        </div>
      </div>
    </div>
    <!-- //서브 상단 -->
    <!-- 서브 탭메뉴 -->
    <div class="tabMenu" v-if="meta !== 'point'">
      <div class="container">
        <router-link
          v-for="menu in tab"
          :key="menu.id"
          :to="menu.link"
          :class="{current: menu.id === depth2.id}">
          <span>{{menu.name}}</span>
        </router-link>
      </div>
    </div>
    <!-- //서브 탭메뉴 -->
    <!-- 상세 탭버튼 -->
    <div class="tabMenu" v-if="meta === 'point'">
      <div class="container">
        <router-link
            v-for="menu in depth2.children"
            :key="menu.id"
            :to="menu.link"
            :class="{current: menu.id === depth3.id}">
          <span>{{menu.name}}</span>
        </router-link>
      </div>
    </div>
    <!-- //상세 탭버튼 -->
  </div>
</template>

<script>
export default {
  name: 'FanzoneTop',
  computed: {
    depth1 () {
      return {
        id: this.page.id,
        name: this.page.name,
        link: this.page.link
      }
    },
    depth2 () {
      const fanzone = this.$route.meta.fanzone
      const current = this.tab.find(x => x.id === fanzone)
      return {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        breadCrumb: current ? current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link,
        children: current.children
      }
    },
    depth3 () {
      let detail = this.$route.params.cate
      if (this.meta === 'point') {
        detail = this.$route.meta.cate
      }
      const depth2 = this.depth2
      const current = depth2 && depth2.children ? depth2.children.find(x => x.id === detail) : null
      return current ? {
        id: current.id,
        name: current.name,
        link: current.link
      } : null
    },
    meta () {
      return this.$route.meta.fanzone
    }
  },
  watch: {
    $route (to, from) {
      this.tabOpen = false
    }
  },
  created () {
    this.tabOpen = false
    document.addEventListener('click', this.onClick)
    const imgSrc = 'https://i.imgur.com/bkmKAyw.png'
    this.bg = `url(${imgSrc})`
  },
  data () {
    return {
      bg: '',
      page: {
        id: 'fanzone',
        name: 'FAN ZONE',
        link: '/fanzone'
      },
      tab: [
        { id: 'faq', name: 'FAQ', link: '/fanzone/faq/all',
          children: [
            { id: 'all', name: '전체', link: '/fanzone/faq/all' },
            { id: 'ticket', name: '티켓', link: '/fanzone/faq/ticket' },
            { id: 'season', name: '시즌권', link: '/fanzone/faq/season' },
            { id: 'stadium', name: '경기장', link: '/fanzone/faq/stadium' },
            { id: 'homepage', name: '홈페이지', link: '/fanzone/faq/homepage' },
            { id: 'app', name: '모바일앱', link: '/fanzone/faq/app' },
            { id: 'clubhouse', name: '클럽하우스', link: '/fanzone/faq/clubhouse' },
            { id: 'etc', name: '기타', link: '/fanzone/faq/etc' }
          ]
        },
        { id: 'point', name: '포인트/쿠폰 안내', link: '/fanzone/point',
          children: [
            { id: 'ticketPoint', name: '티켓 포인트 사용 안내', link: '/fanzone/point/ticketPoint' },
            { id: 'mdPoint', name: 'MD 포인트 사용 안내', link: '/fanzone/point/mdPoint' },
            { id: 'coupon', name: '쿠폰 사용 안내', link: '/fanzone/point/coupon' },
          ]
        },
        { id: 'cheersong', name: '응원가', link: '/fanzone/cheersong' },
        { id: 'supporters', name: '서포터즈', link: '/fanzone/supporters' },
        { id: 'event', name: '이벤트', link: '/fanzone/event' },
        { id: 'eventPhoto', name: '이벤트 포토', link: '/fanzone/eventPhoto' }

      ],
      tabOpen: false,
    }
  }
}
</script>
