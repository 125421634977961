<template>
  <div class="mainSponsor">
    <div class="container">
      <ul class="sponsorList mainList">
        <li v-for="(item, index) in mainSponsor"
          :key="index">
          <a v-if="item.link" :href="item.link" target="_blank"></a>
          <img :src="require(`@/assets/img/sponsor/sponsor_${item.name}.png`)" alt="">
        </li>
      </ul>
      <ul class="sponsorList centerList">
        <li v-for="(item, index) in centerSponsor"
            :key="index">
          <a v-if="item.link" :href="item.link" target="_blank"></a>
          <img :src="require(`@/assets/img/sponsor/sponsor_${item.name}.png`)" alt="">
        </li>
      </ul>
      <ul class="sponsorList">
        <li v-for="(item, index) in sponsor"
          :key="index"
          :class="{main: item.main}">
          <a v-if="item.link" :href="item.link" target="_blank"></a>
          <img :src="require(`@/assets/img/sponsor/sponsor_${item.name}.png`)" alt="">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MainSponsor',
  created () {
  },
  data () {
    return {
      mainSponsor: [
        {name: 'hyundai', main: true, link: 'https://www.hyundai.com'},
        {name: 'adidas', main: true, link: 'https://www.adidas.co.kr'},
        {name: 'kumho', main: true, link: 'https://www.kumhotire.com/ko/index.do'},
      ],
      centerSponsor: [
        {name: 'jeonbuk', link: 'https://www.jeonbuk.go.kr'},
      ],
      sponsor: [
        {name: 'jeonju', link: 'https://www.jeonju.go.kr'},
        {name: 'wanju', link: 'https://www.wanju.go.kr'},
        {name: 'jbbank', link: 'https://www.jbbank.co.kr'},
        {name: 'naver', link: 'https://www.naver.com/'},
        {name: 'shinhyeob', link: 'http://www.cu.co.kr/cu/main.do'},

        {name: 'gochang', link: 'https://www.gochang.go.kr'},
        {name: 'hmsec', link: 'https://www.hmsec.com'},
        {name: 'hyundaiwia', link: 'https://www.hyundai-wia.com'},
        {name: 'nexon', link: 'https://www.nexon.com/Home/Game'},
        {name: 'jbnu', link: 'https://www.jbnu.ac.kr'},

        {name: 'buan', link: 'https://www.buan.go.kr'},
        {name: 'gimje', link: 'https://www.gimje.go.kr'},
        {name: 'hyundaicard', link: 'https://www.hyundaicard.com'},
        {name: 'hi', link: 'https://www.hi.co.kr'},
        {name: 'mobis', link: 'https://www.mobis.co.kr'},

        {name: 'muju', link: 'https://www.muju.go.kr'},
        {name: 'jeongeup', link: 'https://www.jeongeup.go.kr'},
        {name: 'jinan', link: 'https://www.jinan.go.kr'},
        {name: 'hitejinro', link: 'https://www.hitejinro.com'},
        {name: 'fedex', link: 'https://www.fedex.com/ko-kr/home.html'},

        {name: 'ticketlink', link: 'https://www.ticketlink.co.kr'},
        {name: 'haevichi', link: 'https://www.haevichi.com'},
        {name: 'theocean', link: 'https://theoceanresort.co.kr '},
        {name: 'emons', link: 'https://www.emons.co.kr'},

        // {name: 'isaemangeum', link: 'https://www.isaemangeum.co.kr'},
        // {name: 'jangsu', link: 'https://www.jangsu.go.kr'},
        // {name: 'jbtourpass', link: '#'},
        // {name: 'harim', link: 'https://www.harim.com'},
        // {name: 'gloucester', link: 'http://jeonju.gloucester.co.kr/'},
        // {name: 'imsil', link: 'https://www.imsil.go.kr'},

      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.mainSponsor {
  padding: 120px 0;
  text-align: center;
}
</style>
