<template>
  <div class="matchGame hyundai" v-if="loading">
    <div class="subTop">
      <!-- 서브 상단 -->
      <div class="top">
        <div class="bgImg" :style="{backgroundImage: bg}"></div>
        <div class="container">
          <div class="breadCrumb">
            홈 <span class="arr">&#8250;</span>
            <router-link to="/match">MATCH</router-link> <span class="arr">&#8250;</span>
            <router-link to="/match/schedule">프로팀</router-link> <span class="arr">&#8250;</span>
            <router-link to="/match/schedule/result">경기결과</router-link>
          </div>
          <div class="gameResult">
            <span class="league">{{data.match_title}}</span>
            <div class="game emblem">
              <div class="team left">
                <img
                    :src="data.homeLogoImg"
                    alt=""
                />
                {{data.home_team_name}}<span class="displayMobile"></span>
              </div>
              <div class="center">
                <div class="score ess">{{data.home_team_goal}}</div>
                <div class="vs">
                  VS
                  <div class="round" v-if="data.game_type_name"><span>{{data.game_type_name}}</span></div>
                </div>
                <div class="score">{{data.away_team_goal}}</div>
              </div>
              <div class="team right">
                <img
                    :src="data.awayLogoImg"
                    alt=""
                />
                {{data.away_team_name}}<span class="displayMobile"></span>
              </div>
            </div>
            <div class="info">
              <ul class="gameRecord left">
                <template v-if="data.home_team_goal_players.length!==0">
                  <li v-for="(data, i) in data.home_team_goal_players" :key="i"><strong>{{replaceText('num', data)}}’</strong> {{replaceText('name', data)}}</li>
                </template>
              </ul>
              <div class="center">
                <div class="time">{{data.game_time}}</div>
                <div class="date">{{data.game_date}}({{data.yoil}})</div>
                <div class="stadium" style="font-size: 15px">{{data.field_name}}</div>
              </div>
              <ul class="gameRecord right">
                <template v-if="data.away_team_goal_players.length!==0">
                  <li v-for="(data, i) in data.away_team_goal_players" :key="i">{{replaceText('name', data)}} <strong>{{replaceText('num', data)}}’</strong></li>
                </template>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!-- //서브 상단 -->
      <!-- 서브 탭메뉴 -->
      <div class="tabBtns mobileDropdown" :class="{open: tabOpen}">
        <div class="container">
          <div class="dropdownBox">
            <button type="button" class="btn btnDropdown" @click="tabOpen = !tabOpen">{{tab.find(x => x.id === current).name}}</button>
            <div class="dropdownGroup">
              <router-link
                v-for="menu in tab"
                :key="menu.id"
                :to="`/match/game/${gameId}/${menu.id}?type=${type}`"
                :class="{white: menu.id !== current}"
                class="btn">
                <span>{{menu.name}}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <!-- //서브 탭메뉴 -->
    </div>
    <div class="matchSection">
      <div class="container">
        <router-view class="detailBox"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import dayjs from 'dayjs'
import bgPic from '@/assets/img/bg_gameSummury.png'
import 'dayjs/locale/ko'
import imgPath from "@/library/imgPath"
dayjs.locale('ko')

export default {
  name: 'MatchGame',
  props: [
    'id'
  ],
  computed: {
    gameId () {
      return this.id
    },
    current () {
      return this.$route.meta && this.$route.meta.detail
    }
  },
  watch: {
    '$route' (to, from, next) {
      if (to.meta.detail !== from.meta.detail) {
        this.tabOpen = false
      }
    },
    gameId () {
      this.init()
    }
  },
  async created() {
    this.type = this.$route.query.type
    await this.init();
    document.addEventListener( 'click', this.onClick )
  },
  data () {
    return {
      bg: '',
      tab: [
        { id: 'summary', name: '경기요약' },
        { id: 'review', name: '경기리뷰' },
        { id: 'record', name: '출전기록' },
        { id: 'photo', name: '경기사진' },
        { id: 'highlight', name: '하이라이트' }
      ],
      tabOpen: false,
      data:{
        home_team_goal_players: [],
        away_team_goal_players: []
      },
      loading: false,
      type: ''
    }
  },
  methods: {
    getRouteName (name) {
      function capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
      return `matchGame${capitalizeFirstLetter(name)}`
    },

    async init(){
      // userAgent check
      // if JeonBukWebView has userAgent, then change the title
      let type = this.$route.query.type
      if (navigator.userAgent.indexOf('JeonBukWebView') > -1) {
        if(!this.$route.query.type) {

          const game_date = this.$route.params.id;
          const game_year = game_date.substring(0, 4);
          const game_year_month = game_date.substring(0, 7);
          const match = await http.get('/match/schedule/result', {params: {game_year}})

          match.data.data[game_year_month].forEach((data) => {
            if (data.game_date === game_date) {
              switch (data.meet_title) {
                case 'K League 1':
                  type = 'kleague'
                  break
                case 'ACL':
                  type = 'afc'
                  break
                case 'KOREAN CUP':
                  if (game_year < 2024) {
                    type = 'facup'
                  } else {
                    type = 'koreancup'
                  }
                  break
                case 'ETC':
                  type = 'etc'
                  break
                case 'ACL2':
                  type = 'afc2'
                  break
                case 'ACLE':
                  type = 'afce'
                  break
              }
            }
          })
        }
      }
      const match = await http.get(`/match/schedule/${this.$route.params.id}?type=${type}`)
      switch (match.data.data.match.match_title){
        case 'K League 1':
          match.data.data.match.match_title = 'K LEAGUE'
      }
      if (match.data.data.content.bgImagePath !== null) {
        this.bg = `url(${match.data.data.content.bgImagePath})`
      } else {
        this.bg = `url(${bgPic})`
      }
      match.data.data.match.yoil = dayjs(match.data.data.match.game_date).format('dd')
      match.data.data.match.game_date = dayjs(match.data.data.match.game_date).format('YYYY.MM.DD')
      match.data.data.match.game_time = match.data.data.match.game_time.substring(0,5)
      match.data.data.match.homeLogoImg = imgPath.setTeamLogo(match.data.data.match.home_team)
      match.data.data.match.awayLogoImg = imgPath.setTeamLogo(match.data.data.match.away_team)
      if (match.data.data.match.home_team_goal_players) {
        this.home_team_goal_players = match.data.data.match.home_team_goal_players
      }
      if (match.data.data.match.away_team_goal_players) {
        this.away_team_goal_players = match.data.data.match.away_team_goal_players
      }
      this.data = match.data.data.match
      this.$store.state.matchData = match.data.data
      this.loading = true
    },
    onClick (e) {
      const target = e.target
      if (!target.closest('.dropdownBox')) {
        this.tabOpen = false
      }
    },
    replaceText(type, el){
      if(type === 'num') {
        return el.replace(/[^0-9]/g,"")
      }
      if(type === 'name') {
        return el.replace(/(^\s*[0-9’‘']*\s*|\s*[0-9’‘']*\s*$)/g, '')
      }
    }
  }
}
</script>
