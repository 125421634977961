<template>
  <div>
    <div class="container">
      <div v-if="state">
        <div class="staffList">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="staffItem">
            <img v-if="item.img!==null" :src="staffImg(item.img)" :alt="item.name">
            <div class="info">
              <div class="role">{{item.role}}</div>
              <div class="name">{{item.kor_name}}</div>
              <div class="en">{{item.eng_name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="waitingSeason"><img src="@/assets/img/bg_waitingSeason.png" /> </div>
    </div>
  </div>
</template>

<script>
import http from '@/api/index'
import imgPath from "@/library/imgPath"

export default {
  name: 'supportStaff',
  data () {
    return {
      list:[],
      state: true,
    }
  },
  created() {
    this.init()
  },
  methods: {
    staffImg (img) {
      try {
        return imgPath.setStaff(img)
      } catch (e) {
        return null
      }
    },
    async init(){
      const data = await http.get('/player/staff/support')
      this.list = data.data.data
    }
  }
}
</script>
