<template>
  <div class="subTop hyundai">
    <!-- 서브 상단 -->
    <div class="top">
      <div class="container">
        <div class="breadCrumb">
          홈 <span class="arr">&#8250;</span>
          <router-link :to="depth1.link">{{depth1.name}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth2.breadCrumb}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth3.breadCrumb}}</router-link>
        </div>
        <div class="topTitle">
          <p class="cate">{{depth1.name}}</p>
          <p class="subCate">{{depth2.name}}</p>
        </div>
      </div>
    </div>
    <!-- //서브 상단 -->
    <!-- 서브 탭메뉴 -->
    <div class="tabMenu">
      <div class="container">
        <router-link
            v-for="menu in tab"
            :key="menu.id"
            :to="menu.link"
            :class="{current: menu.id === depth2.id}">
          <span>{{menu.name}}</span>
        </router-link>
      </div>
    </div>
    <!-- //서브 탭메뉴 -->
    <!-- 상세 탭버튼 -->
    <div class="container">
      <div class="tabBtns fill" v-if="depth2.children.length !== 0">
        <router-link
            v-for="tab in depth2.children"
            :key="tab.id"
            :to="tab.link"
            :class="{white: tab.id !== depth3.id}"
            class="btn">
          {{tab.name}}
        </router-link>
      </div>
    </div>
    <!-- //상세 탭버튼 -->
  </div>
</template>

<script>
export default {
  name: 'TeamTop',
  created() {

  },
  computed: {
    depth1 () {
      return {
        id: this.page.id,
        name: this.page.name,
        link: this.page.link
      }
    },
    depth2 () {
      const team = this.$route.meta.team
      const current = this.tab.find(x => x.id === team)
      return {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        breadCrumb: current ? current.breadCrumb || current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link,
        children: current.children
      }
    },
    depth3 () {
      const detail = this.$route.params.position || this.$route.meta.detail
      const depth2 = this.depth2
      const current = depth2.children.find(x => x.id === detail)
      return {
        id: current ? current.id : depth2.id,
        name: current ? current.name : depth2.name,
        breadCrumb: current ? current.breadCrumb || current.name : depth2.name,
        link: current ? current.link : this.depth2.link
      }
    }
  },
  data () {
    return {
      page: {
        id: 'team',
        name: 'TEAM',
        link: '/team'
      },
      tab: [
        { id: 'proteam', name: '프로팀', breadCrumb: '프로팀', link: '/team/proteam/all', // breadCrumb: 브레드크럼용 짧은 이름, 없으면 name
          children: [
            { id: 'all', name: 'ALL', link: '/team/proteam/all' },
            { id: 'gk', name: 'GK', link: '/team/proteam/gk' },
            { id: 'df', name: 'DF', link: '/team/proteam/df' },
            { id: 'mf', name: 'MF', link: '/team/proteam/mf' },
            { id: 'fw', name: 'FW', link: '/team/proteam/fw' },
          ]
        },
        { id: 'staff', name: '코칭스태프', breadCrumb: '코칭스태프', link: '/team/staff/headcoach',
          children: [
            { id: 'headcoach', name: '감독', link: '/team/staff/headcoach' },
            { id: 'coachingstaff', name: '코칭스태프', link: '/team/staff/coachingstaff' },
            { id: 'supportstaff', name: '지원스태프', link: '/team/staff/supportstaff' },
            { id: 'coachingstaffN', name: 'N팀 코칭스태프', link: '/team/staff/coachingstaffN' },
          ]
        },
        {id: 'youth', name: '유소년 소개', breadCrumb: '유소년 소개', link: '/team/youth',
          children: [
            // { id: 'youth', name: '유소년 소개', link: '/team/youthh' },
          ]
        },
        { id: 'u18', name: 'U18', breadCrumb: 'U18', link: '/team/u18/all',
          children: [
            { id: 'all', name: 'ALL', link: '/team/u18/all' },
            { id: 'gk', name: 'GK', link: '/team/u18/gk' },
            { id: 'df', name: 'DF', link: '/team/u18/df' },
            { id: 'mf', name: 'MF', link: '/team/u18/mf' },
            { id: 'fw', name: 'FW', link: '/team/u18/fw' },
            { id: 'staff', name: '코칭스태프', link: '/team/u18/staff' },
          ]
        },
        { id: 'u15', name: 'U15', breadCrumb: 'U15', link: '/team/u15/all',
          children: [
            { id: 'all', name: 'ALL', link: '/team/u15/all' },
            { id: 'gk', name: 'GK', link: '/team/u15/gk' },
            { id: 'df', name: 'DF', link: '/team/u15/df' },
            { id: 'mf', name: 'MF', link: '/team/u15/mf' },
            { id: 'fw', name: 'FW', link: '/team/u15/fw' },
            { id: 'staff', name: '코칭스태프', link: '/team/u15/staff' },
          ]
        },
        { id: 'u12', name: 'U12', breadCrumb: 'U12', link: '/team/u12/all',
          children: [
            { id: 'all', name: 'ALL', link: '/team/u12/all' },
            { id: 'u8', name: 'U8', link: '/team/u12/u8' },
            { id: 'u10', name: 'U10', link: '/team/u12/u10' },
            { id: 'u12', name: 'U12', link: '/team/u12/u12' },
            { id: 'staff', name: '코칭스태프', link: '/team/u12/staff' },
          ]
        },
      ]
    }
  }
}
</script>
<style>
.teamBtn{
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 44px;
  padding-bottom: 40px;
  min-width: 135px;
  text-align: center;
  font-size: 16px;
}
@media screen and (max-width: 626px){
  .teamBtn{
    overflow: scroll;
    justify-content: normal;
    display: flex;
    padding-top: 44px;
    padding-bottom: 40px;
    text-align: center;
    font-size: 16px;
  }
}
</style>
