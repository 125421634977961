<template>
  <div class="memberContent hyundai">
    <div class="container">
      <div class="memberBox" style="max-width: 400px; background-color: #FFFFFF">
        <div class="topTitleForErr">
          <div class="title">ERROR 404</div>
          <div class="subTitle" style="font-weight: 400; line-height: 18px"></div>
          존재하지 않는 주소, 페이지를 입력하셨거나,<br>
          현재 페이지가 변경, 삭제되어 찾을 수 없습니다.
          <div class="formBtns formBtns2">
            <button class="btn full primary1" @click="go">메인페이지</button>
          </div>
          <div class="formRow">
            <button class="btn full primary2" @click="goBack">이전으로</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ErrorPage',
  data() {
    return {
      previousRoute: null, // 이전 페이지 경로 저장
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.previousRoute = from.fullPath; // 이전 경로 저장
    });
  },
  mounted() {
    document.getElementsByTagName('html')[0].classList.add('memberPage');
    document.getElementsByTagName('body')[0].classList.add('memberPage');
  },
  methods: {
    go() {
      this.$router.push('/');
    },
    goBack() {
      if (this.previousRoute && this.previousRoute.includes('/match/game/')) {
        this.$router.push({ name: 'matchScheduleResult' });
      } else if (this.previousRoute) {
        this.$router.push(this.previousRoute);
      } else {
        this.$router.push('/'); // 이전 경로가 없을 경우 홈으로
      }
    }
  },
  beforeDestroy() {
    document.getElementsByTagName('html')[0].classList.remove('memberPage');
    document.getElementsByTagName('body')[0].classList.remove('memberPage');
  },
};

</script>
<style lang="scss" scoped>
</style>
