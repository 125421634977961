<template>
  <div class="subTop hyundai">
    <!-- 서브 상단 -->
    <div class="top">
      <div class="container">
        <div class="breadCrumb">
          홈 <span class="arr">&#8250;</span>
          <router-link :to="depth1.link">{{depth1.name}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth2.link">{{depth2.name}}</router-link> <span class="arr">&#8250;</span>
          <router-link :to="depth3.link">{{depth3.name}}</router-link>
        </div>
        <div class="topTitle">
          <p class="cate">{{depth2.name}}</p>
          <p class="subCate">{{depth3.name}}</p>
        </div>
      </div>
    </div>
    <!-- //서브 상단 -->
    <!-- 서브 탭메뉴 -->
    <!-- (참고)티켓은 2depth 없이 3depth 메뉴가 탭메뉴로 구성됨 -->
    <div class="tabMenu">
      <div class="container">
        <router-link
          v-for="menu in depth2.children"
          :key="menu.id"
          :to="menu.link"
          :class="{current: menu.id === depth3.id}">
          <span>{{menu.name}}</span>
        </router-link>
      </div>
    </div>
    <!-- //서브 탭메뉴 -->
  </div>
</template>

<script>
export default {
  name: 'TicketTop',
  computed: {
    depth1 () {
      return {
        id: this.page.id,
        name: this.page.name,
        link: this.page.link
      }
    },
    depth2 () {
      const ticket = this.$route.meta.ticket
      const current = this.tab.find(x => x.id === ticket)
      return {
        id: current ? current.id : this.depth1.id,
        name: current ? current.name : this.depth1.name,
        link: current ? current.link : this.depth1.link,
        children: current.children
      }
    },
    depth3 () {
      const detail = this.$route.meta.detail
      const depth2 = this.depth2
      const current = depth2 && depth2.children ? depth2.children.find(x => x.id === detail) : null
      return current ? {
        id: current.id,
        name: current.name,
        link: current.link
      } : null
    }
  },
  data () {
    return {
      page: {
        id: 'ticket',
        name: 'TICKET',
        link: '/ticket'
      },
      tab: [
        { id: 'reservation', name : '나의티켓', link: '/ticket/reservation',
          children: [
            // { id: 'intro', name : '티켓예매', link: '/ticket/reservation/intro' },
            { id: 'history', name : '예매내역', link: '/ticket/reservation/history' },
            // { id: 'policy', name : '티켓정책', link: '/ticket/reservation/policy' },
            { id: 'info', name : '예매, 취소안내', link: '/ticket/reservation/info' }
          ]
        },
        { id: 'season', name : '시즌티켓', link: '/ticket/season',
          children: [
            // { id: 'intro', name : '시즌권 안내', link: '/ticket/season/intro' },
            // { id: 'benefit', name : '시즌권 혜택', link: '/ticket/season/benefit' },
            { id: 'info', name : '시즌티켓 구매안내', link: '/ticket/season/info' },
            // { id: 'parking', name : '시즌티켓 주차증 구매', link: '/ticket/season/parking' },
          ]
        },
        // { id: 'groupGuide', name : '단체 안내', link: '/ticket/groupGuide',
        //   children: [
        //     { id: 'info', name : '안내/혜택', link: '/ticket/groupGuide/info' },
        //   ]
        // },
        { id: 'skybox', name : '스카이박스', link: '/ticket/skybox',
          children: [
            { id: 'info', name : '안내', link: '/ticket/skybox/info' },
            // { id: 'entrance', name : '입장안내', link: '/ticket/skybox/entrance' },
            // { id: 'reservation', name : '예약', link: '/ticket/skybox/reservation' }
          ]
        },
        { id: 'stadium', name : '경기장 안내', link: '/ticket/stadium',
          children: [
            // { id: 'intro', name : '좌석 안내', link: '/ticket/stadium/intro' },
            // { id: 'location', name : '경기장 위치', link: '/ticket/stadium/location' },
            // { id: 'transport', name : '대중교통', link: '/ticket/stadium/transport' },
            // { id: 'parking', name : '주차장 안내', link: '/ticket/stadium/parking' },
            // { id: 'guideline', name : '경기장 안전 가이드라인', link: '/ticket/stadium/guideline' },
            { id: 'emergency', name : '경기장 비상 대피 안내', link: '/ticket/stadium/emergency' },
            // { id: 'facility', name : '시설 안내', link: '/ticket/stadium/facility' },
            // { id: 'entrance', name : '입장 안내', link: '/ticket/stadium/entrance' }
          ]
        },
        { id: 'service', name : '티켓 공지사항', link: '/ticket/service',
          children: [
            { id: 'faq', name : 'FAQ', link: '/ticket/service/faq/ticket' },
            { id: 'notice', name : '티켓 공지사항', link: '/ticket/service/notice'},
            { id: 'promotion', name : '티켓 프로모션', link: '/ticket/service/promotion' },
            { id: 'event', name : '홈경기 이벤트', link: '/ticket/service/event' }
          ]
        }
      ],
      tabOpen: false,
      dashboard: null
    }
  }
}
</script>
